<template>
  <router-view/>
</template>
<style lang="scss">
.flickity-viewport {
  transition: height 0.2s;
}
@media (max-width:500px){
  .flickity-prev-next-button {
      top: 50%;
      width: 18px !important;
      height: 18px !important;
      border-radius: 50%;
      transform: translateY(-50%);
  }
 }
 @media (min-width:500px){
  .flickity-prev-next-button {
      top: 50%;
      width: 25px !important;
      height: 25px !important;
      border-radius: 50%;
      transform: translateY(-50%);
  }
 }
</style>
