import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/second-ques',
    name: 'second',
    component: () => import(/* webpackChunkName: "about" */ '../views/SecondQuestion.vue')
  },
  {
    path: '/third-ques',
    name: 'third',
    component: () => import(/* webpackChunkName: "about" */ '../views/ThirdQuestion.vue')
  },
  {
    path: '/four-ques',
    name: 'four',
    component: () => import(/* webpackChunkName: "about" */ '../views/FourQuestion.vue')
  },
  {
    path: '/five-ques',
    name: 'five',
    component: () => import(/* webpackChunkName: "about" */ '../views/FiveQuestion.vue')
  },
  {
    path: '/offer',
    name: 'Offer',
    component: () => import(/* webpackChunkName: "about" */ '../views/Offer.vue')
  },
  {
    path: '/product-list',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductList.vue')
  },
  {
    path: '/video-1',
    name: 'video1',
    component: () => import(/* webpackChunkName: "about" */ '../views/video/Video1.vue')
  },
  {
    path: '/video-trouser',
    name: 'video-trouser',
    component: () => import(/* webpackChunkName: "about" */ '../views/video/VideoTrouser.vue')
  },
  {
    path: '/video-test',
    name: 'video-test',
    component: () => import(/* webpackChunkName: "about" */ '../views/test.vue')
  },
  {
    path: '/video-2',
    name: 'video-2',
    component: () => import(/* webpackChunkName: "about" */ '../views/video/Video2.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
