<template>
  <div class="home">
      <div class="row">
        <div class="col-12 col-md-6 first-div full-height">
            <div class="back-arrow cursorPointer">
                <!-- <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 6.5H1" stroke="#462721" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6 11.5L1 6.5L6 1.5" stroke="#462721" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg> -->
                <!-- <span class="arrow-text">Back</span> -->
            </div>
            <div class="question-main">
                <div class="question-breadcrumb ">
                    <span class="breadcrumb-text">1</span>
                    <svg width="28" height="2" viewBox="0 0 28 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line y1="1" x2="28" y2="1" stroke="#462721" />
                    </svg>
                    <span class="breadcrumb-text">5</span>
                </div>
                <div class="question-text" >
                  {{viewFirstQuestionAndAnswerData.title}}
                    <!-- What type of <span class="colored-text">shirt</span> are you looking forward to buy? -->
                </div>
                <div v-show="isAnswerSelected" style="display:flex;color:red; margi-top:.2rem;">Select Option</div>
                <div class="btnContainer" @click="postAnswer">
                   <button class="btn ">Next</button>
                </div>
            </div>
      </div>
      <div class="col-12 col-md-6 second-div">
          <div class="text-center">
              <div class="row g-0 full-height align-items-center">
                  <div class="col mx-2 d-flex justify-content-center cursorPointer" style="border: solid 1px #462721; position:relative"
                    v-for="data of viewFirstQuestionAndAnswerData.answers" 
                    :key="data.id" @click="slectedAnswer(data.question_id, data.id)"
                  >
                      <div class="overlay"></div>
                      <img v-show="data.id==answerId" src="../assets/selected.png" class="img-fluid selectAnswer"/>
                      <img :src="'https://bringin-shoppable.sgp1.cdn.digitaloceanspaces.com/'+data.answer_icon" class="p-1 img-fluid ">
                      <div class="title">{{data['answer_label']}}</div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
export default {
  name: 'Home',
  data(){
    return{
      customerId:'',
      viewQuestionAndAnswerData: [],
      viewFirstQuestionAndAnswerData:{},
      questionId:'',
      answerId:'',
      isAnswerSelected: false
    }
  },
  mounted(){
    this.getlocalStorageData();
    this.getQuestionandAnswer();
  },
  methods:{
    getlocalStorageData(){
      if(localStorage.customerId)
      {
        this.customerId = JSON.parse(localStorage.customerId)
      }
      else {
        this.getUserId();
      }
    },
    getUserId(){
      let self = this
      let url = "https://blackberry-questionnaire.lyxelandflamingotech.in/api/start"
      axios.get(url).then(function (res) {
        self.customerId=res.data
        localStorage.customerId = JSON.stringify(res.data)
      }).catch(function (error) {
          console.log("chdck",error.response);
      });   
    },
    getQuestionandAnswer(){
        let self = this
        let url = "https://blackberry-questionnaire.lyxelandflamingotech.in/api/questions"
        axios.get(url).then(function (res) {
          // self.userId=res.data
          self.viewQuestionAndAnswerData=res.data
          localStorage.viewQuestionAndAnswerData = JSON.stringify(res.data)
          self.viewFirstQuestionAndAnswerData=res.data[0]
        }).catch(function (error) {
        });   
    },
    slectedAnswer(questionId, answerId){
      this.questionId= questionId,
      this.answerId= answerId,
      this.isAnswerSelected= false
    },
    postAnswer(){
        let self = this
        if(!self.answerId){
          this.isAnswerSelected = true
          return false;
        }
        const formData = new FormData();
        formData.append('customer_id',self.customerId)
        formData.append('question_id',self.questionId)
        formData.append('answer_id',self.answerId)
        console.log("formData", formData)
        let url = "https://blackberry-questionnaire.lyxelandflamingotech.in/api/response"
        axios.post(url,formData).then(function (res) {
          self.$router.push("/second-ques");
        }).catch(function (error) {
            console.log("chdck",error);
        });   
    }
  }

}
</script>
<style lang="scss" scoped>
.row{
  padding:0px;
  margin:0px;
}
.full-height{
    height: calc(50vh);
}
.cursorPointer{
  cursor: pointer;
}
.second-div {
    background: #FFF3E7;
}

.back-arrow{
    display: inline;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 11px;

    position: absolute;
    width: 57px;
    height: 19px;
    left: 31px;
    top: 30px;
}

.arrow-text {
    width: 36px;
    height: 19px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-transform: capitalize;

    color: #462721;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.breadcrumb-text {

width: 12px;
height: 23px;

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 23px;
text-transform: capitalize;

color: #462721;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.question-breadcrumb{
  text-align: start;
}
.question-main {
    position: relative;
    width: 60%;
    height: 40%;
    left: 20%;
    top: 30%;
}
.colored-text {

font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 105%;
/* or 125% */

text-transform: capitalize;

color: #C9A477;
}
.question-text {
margin-top: 1.5rem;
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 105%;
text-align: initial;
/* or 125% */

text-transform: capitalize;

color: #462721;
}
.options {

}
.btnContainer{
  display: flex;
  justify-content: start;
  margin-top: 1rem;
  button{
    background: #462721;
    color: #fff;
    width: 200px;
    height: 60px;
    &:hover{
        background: #462721;
        color: #fff;
        width: 200px;
        height: 60px;
        cursor: pointer;
    }
  }

}
@media (max-width: 480px) {
    .full-height {
        height: calc(50vh);
    }
    .colored-text, .question-text{
        font-size: 42px;
        line-height: 117%;
    }
    .colored-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 105%;
    text-transform: capitalize;
    color: #C9A477;
    }

    .question-text {
      margin-top: 1rem;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 105%;
      text-align: initial;
      text-transform: capitalize;
      color: #462721;
    }
    .btnContainer{
      display: flex;
      justify-content: center;
      z-index: 99999999999;
      button{
          background: #462721;
          color: #fff;
          padding: .5rem !important; 
          width: 100%;
          height: auto !important;
          &:hover{
              background: #462721;
              color: #fff;
              padding: .5rem !important;
              width: 100%;
              cursor: pointer;
              height: auto !important;

          }
      }
    }
    .selectAnswer{
      width:1.2rem !important; 
      position: absolute; 
      top: 1rem !important;  
      left: 1rem !important;
    }
}

@media (min-width: 768px) {
    .full-height {
        height: calc(100vh);
    }
    .colored-text, .question-text{
        font-size: 42px;
        line-height: 117%;
    }
}

@media (min-width: 992px) {
    .colored-text, .question-text{
        font-size: 48px;
        line-height: 125%;
    }
}
.selectAnswer{
  width:2rem; 
  position: absolute; 
  top: 1.5rem; 
  left: 2rem;
}
.overlay{
  position: absolute;
  width:100%;
  bottom: 0px;
  height: 80%;
 background: linear-gradient(180deg, rgba(17, 12, 9, 0) 34.03%, #1C130E 97.53%);
}
.title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    position: absolute;
    bottom: 0px;
    color: #fff;
    margin-bottom: 2rem ;
}
</style>